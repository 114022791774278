
$checkColor: #1122d8;
$checkborder: #f6f6f3;
::v-deep .el-checkbox__inner {
  border: none;
  display: none;
}
::v-deep .el-checkbox.is-bordered {
  border-color: $checkborder;
  background: $checkborder;
}
::v-deep .el-checkbox.is-bordered.is-checked {
  border-color: $checkColor;
  color: $checkColor;
}
::v-deep .el-checkbox__input.is-checked + .el-checkbox__label {
  color: $checkColor;
}
::v-deep .el-checkbox.is-bordered + .el-checkbox.is-bordered {
  margin-left: 0px;
  margin-bottom: 0px;
}

@import "./Analysis";
@include inputError;
.second-container {
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  padding: 55px;
  display: flex;
  @include validateForm;
}
.common-span {
  font-size: 20px;
  display: block;
  margin: 20px 0;
}
.title-span {
  margin-left: 10px;
  font-size: 16px;
  line-height: 20px;
}
.common-flex {
  @include InputFlex;
}
.btn-flex {
  margin-left: 40%;
}
.test {
  width: 17%;
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  margin-top: 10px;
}

@keyframes toright {
  0% {
    transform: rotateY(90deg);
  }
  100% {
    transform: rotateY(0deg);
  }
}
.animation {
  animation: toright 0.5s ease 0s;
}
::-webkit-scrollbar {
  display: none;
}
