@mixin validateForm {
    ::v-deep .el-form-item.is-required:not(.is-no-asterisk) > .el-form-item__label:before {
        content: "";
        width: 0px;
        margin-right: 0px;
      }
}

@mixin InputFlex{
  display: flex;
}

@mixin btnFlex {
  display: flex;
  justify-content: center;
  align-items: center;
}

@mixin showScrollbar{
  height: 100%;
  ::v-deep .el-scrollbar__wrap {
    overflow-x: hidden;
    .el-scrollbar__view {
    }
  }
}

@mixin inputError {
  ::v-deep .el-input-number {
    .el-input__inner {
      text-align: left;
    }
    &.isError {
      .el-input__inner {
        border-color: #fd4446;
      }
    }
  }
}



