
$checkColor: #1122d8;
::v-deep .el-checkbox.is-bordered + .el-checkbox.is-bordered {
  margin-left: 0px;
}
::-webkit-scrollbar {
  display: none;
}
::v-deep .el-checkbox.is-bordered {
  height: 70px;
}
::v-deep .el-checkbox__inner {
  margin-bottom: 35px;
}
::v-deep .el-checkbox.is-bordered.is-checked {
  border-color: $checkColor;
}
::v-deep .el-checkbox__input.is-checked .el-checkbox__inner {
  background-color: $checkColor;
  border-color: $checkColor;
}
::v-deep .el-checkbox__input.is-checked + .el-checkbox__label {
  color: $checkColor;
}
@import "./Analysis";
@include inputError;
.third-container {
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  padding: 55px;
  display: flex;
  @include validateForm;
  .goods {
    height: 100%;
    margin-left: 9%;
    &-main {
      display: flex;
      height: 150px;
      flex-direction: column;
      .goods-content {
        height: 100%;
        align-items: center;
        display: flex;
        .goods-box {
          height: 100%;
          width: 30%;
          display: flex;
          background: #ffffff;
          border: 1px solid #dcdfe6;
          border-radius: 5px;
          .goods-imgs {
            display: flex;
            align-items: center;
            width: 20%;
            height: 100%;
            padding-left: 10px;
          }
          .goods-desc {
            display: flex;
            justify-content: center;
            padding-left: 10px;
            flex-direction: column;
            height: 100%;
          }
        }
      }
      .goods-watch {
        margin: 10px 0 10px 0;
        display: flex;
        align-items: center;
        span {
          margin-right: 15px;
        }
      }
    }
  }
}
.goods-window {
  width: 100%;
  height: 400px;
  overflow-y: auto;
  overflow-x: hidden;
  margin-top: 20px;
}
.title {
  width: 7rem;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.news_info {
  width: 6.24rem;
  font-size: 14px;
  color: #333333;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.common-flex {
  @include InputFlex;
}
.btn-flex {
  @include btnFlex;
}
.common-span {
  font-size: 20px;
  display: block;
  margin: 20px 0 0 45px;
}
.btn-bottom {
  margin-top: 90px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.common-group {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  margin-left: 9.5%;
}
.goods-box {
  display: flex;
  div {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    span {
      margin-left: 10px;
    }
  }
}
@keyframes toright {
  0% {
    transform: rotateY(90deg);
  }
  100% {
    transform: rotateY(0deg);
  }
}
.animation {
  animation: toright 0.5s ease 0s;
}
