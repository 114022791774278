
@import "./Analysis";
@include inputError;
.fouth-container {
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  padding: 55px;
  display: flex;
  @include validateForm;
  ::-webkit-scrollbar {
    display: none;
  }
}
.common-flex {
  @include InputFlex;
}
.btn-flex {
  @include btnFlex;
}
